import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { IconButton, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

export default function CopyToClipboard2({
  width,
  copyText,
  flex,
  hideText,
  icon,
}) {
  const [copiedText, setCopiedText] = useState("");
  let style = { width: width || "100%", marginTop: flex ? 4 : 32 };
  if (!flex) {
    style.textAlign = "center";
  }
  if (flex) {
    style.display = "flex";
    style.alignItems = "center";
    style.marginBottom = 12;
  }

  return (
    <div style={style}>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontSize: 12 }}
      >
        {hideText ? `` : copyText}
      </Typography>
      <CopyToClipboard text={copyText} onCopy={() => setCopiedText(copyText)}>
        <div>
          <Tooltip
            title={copiedText === copyText ? "Copiado!" : "Clique para copiar"}
            placement="top"
          >
            <div style={{ cursor: "pointer", marginTop: 12 }}>
              <IconButton>
                {icon ? icon : <FileCopy fontSize="small" />}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </CopyToClipboard>
    </div>
  );
}
