import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Register from "@pages/Register";
import empresaService from "@services/empresaService";
import { Typography } from "@material-ui/core";

//Register for companies Invites and TradeCafe Invites

function RegisterWithInvite({}) {
  const [invite, setInvite] = useState({});
  const [error, setError] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    empresaService
      .getInviteFromToken(token)
      .then((rInvite) => setInvite(rInvite))
      .catch((e) => setError(e.message));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 600,
        flexDirection: "column",
      }}
    >
      {" "}
      {error ? (
        <h1>{error}</h1>
      ) : (
        <>
          <div style={{ display: "flex", marginTop: 100, gap: 12 }}>
            <Typography variant="h4">Ola </Typography>
            <Typography
              variant="h4"
              style={{ fontStyle: "bold", textDecoration: "underline" }}
            >
              {invite?.nome}
            </Typography>
          </div>
          <div style={{ marginTop: 48, gap: 12 }}>
            <Typography variant="body1" align="center">
              Você foi convidado por
              {` ${invite?.usuario?.name}`} para se juntar{" "}
              {invite?.empresa?.nomeFantasia
                ? ` a empresa ${invite?.empresa?.nomeFantasia} n`
                : ` `}
              a nossa comunidade Café Trade.
            </Typography>
            <Typography variant="body2">
              Estamos empolgados em tê-lo(a) a bordo! Por favor, preencha o
              formulário abaixo para completar seu cadastro:
            </Typography>
          </div>

          <Register inviteToken={token} invite={invite} />
        </>
      )}
    </div>
  );
}

export default RegisterWithInvite;
