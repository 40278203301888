import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MainAnimation from "@components/MainAnimation";
import {
  makeStyles,
  TextField,
  Paper,
  Container,
  Box,
  Typography,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import showToast from "../components/Toast";
import empresaService from "../services/empresaService";
import outsideService from "../services/outsideService";
import phoneRegExp from "@utils/phoneRegExp";
import { setAuthInfoCompany } from "@redux/authReducer";
import TCButton from "../components/TCButton";
import TCLoading from "../components/TCLoading";
import { useHistory } from "react-router-dom";
import { setAuthInfo } from "../redux/authReducer";
import { phoneRegExpEmpty } from "../utils/phoneRegExp";
import PhoneInput from "../PhoneInput";
import { TIPOS_EMPRESAS } from "@utils/data/formFields";
import CEPInput from "../components/Company/CepInput";

const useStyles = makeStyles((theme) => ({
  first: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 0,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 100,
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      paddingLeft: 0,
    },
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      flexDirection: "column",
    },
    padding: 20,
  },
  inpt: {
    width: 230,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 12,
  },
  boxCompanyInputs: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 0.68,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
      // flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  isentoCheckbox: {
    paddingLeft: 2,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: 270,
      paddingLeft: 168,
    },
  },
}));

export default function RegisterCompany({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((states) => states.authReducer.user);

  const [cepDone, setCepDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invite, setInvite] = useState(null);

  const schema = pvGetSchema();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: {}, resolver: yupResolver(schema) });

  function send(data) {
    empresaService
      .registerCompany(data)
      .then((rCompany) => {
        showToast.success("Empresa cadastrada");
        dispatch(setAuthInfoCompany(rCompany));
        history.push("/feed");
      })
      .catch((e) => showToast.error(e.message));
  }

  const cep = watch("cep")?.replace("-","")
  useEffect(() => {
    if (cep?.length === 8) {
      return outsideService
        .getCepInfo(cep)
        .then((rResp) => {
          setValue("rua", rResp.street);
          setValue("bairro", rResp.neighborhood);
          setValue("uf", rResp.state);
          setValue("cidade", rResp.city);
        })
        .catch((e) => {})
        .finally(() => {
          setCepDone(true);
        });
    }
    setValue("rua", "");
    setValue("bairro", "");
    setValue("uf", "");
    setValue("cidade", "");
  }, [cep]);

  useEffect(() => {
    if (user?._id) {
      setLoading(true);
      empresaService
        .checkConvite()
        .then((rInvite) => {
          setInvite(rInvite);
        })
        .catch((e) => {
          showToast.error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <MainAnimation>
      <form onSubmit={handleSubmit(send)}>
        <Container
          maxWidth="md"
          style={{
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!user?._id ? (
            <h2>Voce precisa estar logado para registrar uma empresa</h2>
          ) : user?.empresa ? (
            <h2>Voce ja possui uma empresa</h2>
          ) : loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : invite ? (
            <Paper className={classes.paper}>
              <InviteBox invite={invite} setInvite={setInvite} />
            </Paper>
          ) : (
            <Paper className={classes.paper}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 700,
                }}
              >
                <div style={{ marginBottom: 40 }}>
                  <Typography variant="h4" align="center">
                    {"Cadastro Empresa"}
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  align="center"
                  style={{ marginRight: 44, marginLeft: 44 }}
                >
                  Olá, {user?.name}. Após concluir o seu cadastro de acesso ao
                  Trade Café, precisamos que você cadastre a sua empresa. Você
                  será o usuário Master desta empresa. Por favor, preencha os
                  dados abaixo e aguarde a confirmação por e-mail.
                  Atenciosamente, Equipe Trade Café
                </Typography>
                <div className={classes.first}>
                  <FormControl
                    error={errors[`tipo`]}
                    variant="outlined"
                    fullWidth
                    style={{
                      marginTop: 24,
                      marginBottom: 12,
                      display: "flex",
                    }}
                  >
                    <InputLabel>Tipo de Empresa</InputLabel>
                    <Select
                      {...register(`tipo`)}
                      style={{ width: 230 }}
                      defaultValue={getValues ? getValues(`tipo`) : ""}
                      variant="outlined"
                    >
                      {TIPOS_EMPRESAS.map((each) => (
                        <MenuItem value={each}>{each}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div />
                </div>

                <Box className={classes.boxCompanyInputs}>
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"CNPJ"}
                    helperText={errors["cnpj"]?.message}
                    error={errors["cnpj"]}
                    {...register("cnpj")}
                  />
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Email Empresa"}
                    helperText={errors["email"]?.message}
                    error={errors["email"]}
                    {...register("email")}
                  />
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Razao Social"}
                    helperText={errors["razaoSocial"]?.message}
                    error={errors["razaoSocial"]}
                    {...register("razaoSocial")}
                  />
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Nome Fantasia"}
                    helperText={errors["nomeFantasia"]?.message}
                    error={errors["nomeFantasia"]}
                    {...register("nomeFantasia")}
                  />

                  <PhoneInput
                    variant="outlined"
                    classes={classes}
                    errors={errors}
                    register={register}
                    label={"Telefone 1"}
                    name="telefone1"
                  />

                  <PhoneInput
                    variant="outlined"
                    classes={classes}
                    errors={errors}
                    register={register}
                    label={"Telefone 2"}
                    name="telefone2"
                  />
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Inscricao Municipal"}
                    helperText={errors["inscricaoMunicipal"]?.message}
                    error={errors["inscricaoMunicipal"]}
                    {...register("inscricaoMunicipal")}
                  />

                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Website"}
                    helperText={errors["website"]?.message}
                    error={errors["website"]}
                    {...register("website")}
                  />

                  <Box className={classes.isentoCheckbox}>
                    <Typography variant="body" style={{ marginRight: 16 }}>
                      {"Isento"}
                    </Typography>
                    <Checkbox
                      checked={watch("isentoInscricaoEstadual")}
                      onChange={(e) => {
                        setValue("isentoInscricaoEstadual", e.target.checked);
                      }}
                      {...register("isentoInscricaoEstadual")}
                    />
                  </Box>
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Inscricao Estadual"}
                    helperText={errors["inscricaoEstadual"]?.message}
                    error={errors["inscricaoEstadual"]}
                    disabled={watch("isentoInscricaoEstadual")}
                    {...register("inscricaoEstadual")}
                  />

                  {/* ============================================================  CEP  =========================================================== */}
                  {/* <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"CEP"}
                    helperText={errors["cep"]?.message}
                    error={errors["cep"]}
                    {...register("cep")}
                  /> */}
                  <CEPInput
                    classes={classes}
                    errors={errors}
                    register={register}
                    name="cep"
                    variant="outlined"
                  />
                  {cepDone && (
                    <>
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"UF"}
                        helperText={errors["uf"]?.message}
                        error={errors["uf"]}
                        {...register("uf")}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"Cidade"}
                        helperText={errors["cidade"]?.message}
                        error={errors["cidade"]}
                        {...register("cidade")}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"Bairro"}
                        helperText={errors["bairro"]?.message}
                        error={errors["bairro"]}
                        {...register("bairro")}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"Rua"}
                        helperText={errors["rua"]?.message}
                        error={errors["rua"]}
                        {...register("rua")}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"Numero"}
                        helperText={errors["numero"]?.message}
                        error={errors["numero"]}
                        {...register("numero")}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.inpt}
                        label={"Complemento"}
                        helperText={errors["complemento"]?.message}
                        error={errors["complemento"]}
                        {...register("complemento")}
                      />
                    </>
                  )}
                </Box>
                <TCButton
                  size="medium"
                  style={{
                    width: 200,
                    alignSelf: "flex-end",
                    marginTop: 18,
                    marginRight: 30,
                  }}
                >
                  Cadastrar
                </TCButton>
              </div>
            </Paper>
          )}
        </Container>
      </form>
    </MainAnimation>
  );
}

function pvGetSchema() {
  return yup.object().shape({
    cnpj: yup
      .string()
      .required("Preencha o CNPJ")
      .matches(/^[0-9]+$/, "Somente numeros")
      .min(14, "Deve ter 14 digitos")
      .max(14, "Deve ter 14 digitos"),
    email: yup.string().required("Preencha o email").email("Email invalido"),
    nomeFantasia: yup.string().required("Preencha o nome fantasia"),
    razaoSocial: yup.string().required("Preencha a razao social"),
    telefone1: yup.string().matches(phoneRegExp, "Telefone invalido"),
    telefone2: yup.string().matches(phoneRegExpEmpty, "Telefone invalido"),
    isentoInscricaoEstadual: yup.boolean(),
    inscricaoEstadual: yup.string(),
    inscricaoMunicipal: yup.string().required("Preencha a Inscricao Municipal"),
    cep: yup
      .string()
      .required("Preencha seu CEP")
      .min(9, "Deve ter 8 digitos")
      .max(9, "Deve ter 8 digitos"),
    rua: yup.string().required("Preencha sua rua"),
    uf: yup.string().required("Preencha sua uf"),
    bairro: yup.string().required("Preencha sua bairro"),
    numero: yup.string().required("Preencha seu numero"),
    complemento: yup.string().required("Preencha seu complemento"),
  });
}

function InviteBox({ invite, setInvite }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  function acceptConvite() {
    setLoading(true);
    empresaService
      .acceptConvite(invite._id)
      .then((rUsuario) => {
        dispatch(setAuthInfo(rUsuario));
        history.push("/feed");
      })
      .catch((e) => {
        showToast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function refuseConvite() {
    setLoading(true);
    empresaService
      .refuseConvite(invite._id)
      .then((rConvite) => {
        setInvite(null);
      })
      .catch((e) => {
        showToast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Box>
      <Typography variant="h5">
        {invite?.empresa?.nomeFantasia
          ? `A empresa ${invite?.empresa?.nomeFantasia} te convidou para se juntar a ela!`
          : ``}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 12 }}>
        {`Ao aceitar o convite voce tera acesso as informacoes da empresa`}
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 12,
        }}
      >
        <TCButton loading={loading} onClick={acceptConvite}>
          Aceitar
        </TCButton>
        <TCButton loading={loading} onClick={refuseConvite}>
          Recusar
        </TCButton>
      </Box>
    </Box>
  );
}
