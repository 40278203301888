import OBJETO_CAMPOS from "./formFields";

export const LEFT_FILTERS = {};
[
  "regiaoProdutora",
  "anoSafra",
  "especie",
  "processamento",
  "bebidaBase",
  "gostoAdicional",
  "catacao",
  "fava",
  "tipoCafe",
  //"aspecto",
  
].map((rKey) => {
  LEFT_FILTERS[rKey] = OBJETO_CAMPOS[rKey];
});
