import React, { useEffect } from "react";
import { Divider } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import PostInfo from "./PostInfo";
import forumService from "../../services/forumService";
import { useDispatch, useSelector } from "react-redux";
import { setReplies } from "../../redux/forumReducer";

export default function PostRepliesList({ post, theme, repliesList }) {
  const dispatch = useDispatch();

  const user = useSelector((states) => states.authReducer.user);
  useEffect(() => {
    if (!repliesList?.length) {
      forumService
        .getReplies(post._id)
        .then((rReplies) => {
          dispatch(setReplies(post._id, rReplies));
        })
        .catch((e) => console.log(e));
    }
  }, []);
  return (
    <Collapse in timeout="auto" unmountOnExit>
      {repliesList?.map((rReplyPost) => (
        <PostReply post={rReplyPost} theme={theme} user={user} />
      ))}
    </Collapse>
  );
}

function PostReply({ post, theme, user }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 12,
        }}
      >
        <pre style={{ whiteSpace: `pre-wrap` }}>{post.texto}</pre>
        <PostInfo post={post} theme={theme} user={user} isReply />
      </div>
      <Divider />
    </>
  );
}
