import { StyledP } from "./DetailOffer";

export default function TypographyLineBreak({ texto, ...props }) {
  const textWithLineBreaks = texto?.replace(/\r?\n/g, "<br>");
  return (
    <StyledP
      dangerouslySetInnerHTML={{ __html: textWithLineBreaks }}
      {...props}
    >
      {texto}
    </StyledP>
  );
}
