import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Box, IconButton, makeStyles } from "@material-ui/core";
import MainAnimation from "../components/MainAnimation";
import AdminInfo from "@components/AdminPanel/AdminInfo";
import NewCompanies from "@components/AdminPanel/NewCompanies";
import FakeCompanies from "@components/AdminPanel/FakeCompanies";
import FeedbacksList from "@components/AdminPanel/FeedbacksList";
import FakeInteresseOfertas from "@components/AdminPanel/FakeInteresseOfertas";
import InvitesPanel from "@components/AdminPanel/InvitesPanel";
import CotacaoPanel from "@components/AdminPanel/CotacaoPanel";
import ConnectedUsersPanel from "@components/AdminPanel/ConnectedUsersPanel";

import {
  Business,
  LocalGasStation,
  Info,
  PhoneForwarded,
  EmojiEmotions,
  BusinessCenter,
  InsertInvitationRounded,
  FlashOn,
  CastConnected,
} from "@material-ui/icons";
import { StyledBadge } from "@components/Header";
import Companies from "../components/AdminPanel/CompaniesList";

const useStyles = makeStyles((theme) => ({
  containerDiv: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  iconsDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
}));
function AdminPanel() {
  const classes = useStyles();
  const [idx, setIdx] = useState(0);
  const [user, newCompaniesCount] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.newCompaniesCount,
  ]);

  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }

  let iconStyle = {
    fontSize: 35
  }
  return (
    <MainAnimation>
      <Container
        maxWidth={idx !== 6 ? "md" : "xl"}
        className={classes.containerDiv}
      >
        <Box className={classes.iconsDiv}>
          <IconButton onClick={() => setIdx(0)}>
            <Info
              style={iconStyle}
            />
          </IconButton>

          <StyledBadge badgeContent={newCompaniesCount}>
            <IconButton onClick={() => setIdx(1)}>
              <Business
                style={iconStyle}
              />
            </IconButton>
          </StyledBadge>
          <IconButton onClick={() => setIdx(2)}>
            <LocalGasStation
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(3)}>
            <PhoneForwarded
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(4)}>
            <EmojiEmotions
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(5)}>
            <BusinessCenter
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(6)}>
            <InsertInvitationRounded
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(7)}>
            <FlashOn
              style={iconStyle}
            />
          </IconButton>
          <IconButton onClick={() => setIdx(8)}>
            <CastConnected
              style={iconStyle}
            />
          </IconButton>
        </Box>
        {idx === 0 && <AdminInfo />}
        {idx === 1 && <NewCompanies />}
        {idx === 2 && <FakeCompanies />}
        {idx === 3 && <FakeInteresseOfertas />}
        {idx === 4 && <FeedbacksList />}
        {idx === 5 && <Companies />}
        {idx === 6 && <InvitesPanel />}
        {idx === 7 && <CotacaoPanel />}
        {idx === 8 && <ConnectedUsersPanel />}
      </Container>
    </MainAnimation>
  );
}

export default AdminPanel;
