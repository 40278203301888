import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, IconButton, Paper, Typography } from "@material-ui/core";
import MainAnimation from "@components/MainAnimation";
import showToast from "@components/Toast";
import adminService from "../../services/adminService";
import TCLoading from "../TCLoading";
import { CheckCircle, HourglassEmpty, Refresh } from "@material-ui/icons";
import { green, orange } from "@material-ui/core/colors";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Th from "../Tables/components/Th";

function InvitesPanel() {
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState(false);
  const [user] = useSelector((states) => [states.authReducer.user]);

  useEffect(() => {
    getInvites();
  }, []);

  function getInvites() {
    setLoading(true);
    return adminService
      .getInvites()
      .then((rInvites) => {
        setInvites(rInvites);
      })
      .catch((e) => showToast.error(e?.message))
      .finally(() => setLoading(false));
  }

  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="xl" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" gutterBottom style={{ marginTop: 12 }}>
                  Painel de Convites
                </Typography>
                <IconButton onClick={() => getInvites()}>
                  <Refresh />
                </IconButton>
              </div>
              <Convites convites={invites} />
              {/* <Convites
                convites={invites.convitesByEmpresa}
                title="SubConvites para Empresas"
              /> */}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default InvitesPanel;

function Convites({ convites }) {
  return convites ? (
    <div style={{ marginTop: 36, alignSelf: "center" }}>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Data
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Hora
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Empresa
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Usuario
              </Th>
              <Th padding="none" size="small" align="left">
                Convidado
              </Th>
              <Th padding="none" size="small" align="left">
                Email
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Dt Conc
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Hora
              </Th>
              <Th padding="none" size="small" align="center">
                Status
              </Th>
              <Th padding="none" size="small" align="left">
                Empresa
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Tipo
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Dt Conc
              </Th>
              <Th
                padding="none"
                size="small"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                Hora
              </Th>
              <Th padding="none" size="small" align="center">
                Status
              </Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(convites).map((rInvite) => {
              let xCreatedAt = rInvite.createdAt
                ? new Date(rInvite.createdAt)
                : null;
              let xCompletedAt = rInvite.completedAt
                ? new Date(rInvite.completedAt)
                : null;
              let xEmpresaCreatedAt = rInvite?.empresaCadastrada?.createdAt
                ? new Date(rInvite?.empresaCadastrada?.createdAt)
                : null;

              let xHourCreatedAt = String(xCreatedAt?.getHours()).padStart(
                2,
                "0"
              );
              let xMinutesCreatedAt = String(xCreatedAt?.getMinutes()).padStart(
                2,
                "0"
              );
              let xHourCompletedAt = String(xCompletedAt?.getHours()).padStart(
                2,
                "0"
              );
              let xMinutesCompletedAt = String(
                xCompletedAt?.getMinutes()
              ).padStart(2, "0");

              let xHourEmpresaCreatedAt = String(
                xEmpresaCreatedAt?.getHours()
              ).padStart(2, "0");
              let xMinutesEmpresaCreatedAt = String(
                xEmpresaCreatedAt?.getMinutes()
              ).padStart(2, "0");

              return (
                <TableRow key={rInvite._id} style={{ fontSize: 12 }}>
                  <TableCell padding="none" size="small">
                    {xCreatedAt?.toLocaleDateString("pt-BR")}
                  </TableCell>
                  <TableCell
                    padding="none"
                    size="small"
                    style={horizontalPadding}
                  >
                    {xHourCreatedAt
                      ? `${xHourCreatedAt}:${xMinutesCreatedAt}`
                      : ``}
                  </TableCell>

                  <TableCell padding="none" size="small" style={cutTextStyle}>
                    {rInvite?.usuario?.empresa?.nomeFantasia}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {rInvite?.usuario?.name}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {rInvite.nome}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {rInvite.emailConvidado}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {xCompletedAt?.toLocaleDateString("pt-BR")}{" "}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {xCompletedAt
                      ? `${xHourCompletedAt}:${xMinutesCompletedAt}`
                      : null}
                  </TableCell>

                  <TableCell padding="none" size="small" align="center">
                    {rInvite.completed ? (
                      <CheckCircle
                        style={{ color: green[700] }}
                        fontSize="small"
                      />
                    ) : (
                      <HourglassEmpty
                        style={{ color: orange[700] }}
                        fontSize="small"
                      />
                    )}
                  </TableCell>
                  <TableCell padding="none" size="small" style={cutTextStyle}>
                    {rInvite.empresaCadastrada?.nomeFantasia || ``}
                  </TableCell>
                  <TableCell padding="none" size="small" style={cutTextStyle}>
                    {rInvite.empresaCadastrada?.tipo || ""}
                  </TableCell>

                  <TableCell padding="none" size="small">
                    {rInvite.empresa
                      ? ``
                      : xEmpresaCreatedAt?.toLocaleDateString("pt-BR")}{" "}
                  </TableCell>
                  <TableCell
                    padding="none"
                    size="small"
                    style={horizontalPadding}
                  >
                    {rInvite.empresa
                      ? ``
                      : xEmpresaCreatedAt
                      ? `${xHourEmpresaCreatedAt}:${xMinutesEmpresaCreatedAt}`
                      : null}
                  </TableCell>
                  <TableCell padding="none" size="small" align="center">
                    {xEmpresaCreatedAt ? (
                      <CheckCircle
                        style={{ color: green[700] }}
                        fontSize="small"
                      />
                    ) : (
                      <HourglassEmpty
                        style={{ color: orange[700] }}
                        fontSize="small"
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  ) : (
    <h2 style={{ marginTop: 90, textAlign: "center" }}>Sem Convites</h2>
  );
}

let cutTextStyle = {
  // whiteSpace: "nowrap",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  // maxWidth: 80,
};

let horizontalPadding = {
  paddingLeft: 4,
  paddingRight: 8,
};
