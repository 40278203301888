import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { DARK } from "@utils/themes";
import formatDate from "@utils/formatDate";
import { Delete } from "@material-ui/icons";
import forumService from "../../services/forumService";
import {
  handleDeleteAllLists,
  handleDeleteReply,
  handleDeleteReplyCount,
} from "../../redux/forumReducer";
import { useDispatch } from "react-redux";

export default function PostInfo({ post, theme, user, isReply }) {
  const dispatch = useDispatch();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minWidth: 75,
        }}
      >
        <Typography variant="subtitle1" style={{ marginRight: 12 }}>
          {post.usuario?.name}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ color: theme === DARK ? grey[500] : grey[700] }}
        >
          {formatDate(post.createdAt)}
        </Typography>
      </div>

      {post?.usuario?._id === user?._id && (
        <IconButton
          onClick={() =>
            forumService
              .deletePost(post?._id)
              .then((x) => {
                if (isReply) {
                  dispatch(handleDeleteReplyCount(post?.post));
                  return dispatch(handleDeleteReply(post));
                }
                dispatch(handleDeleteAllLists(post?._id));
              })
              .catch((e) => console.log(e))
          }
        >
          <Delete />
        </IconButton>
      )}
    </div>
  );
}
