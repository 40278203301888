import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Typography,
  Divider,
  Box,
  TextareaAutosize,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Close, Comment, Reply, Send, Star, ThumbUp } from "@material-ui/icons";
import { green, grey } from "@material-ui/core/colors";
import { DARK } from "@utils/themes";
import PostInfo from "./PostInfo";
import PostRepliesList from "./PostReplyList";
import { useStyles } from "./MainForum";
import forumService from "../../services/forumService";
import {
  addReply,
  handleAddReplyCount,
  handleChangeAllLists,
} from "../../redux/forumReducer";
import showToast from "../Toast";
import ImgsViewer from "react-images-viewer";
import TCLoading from "../TCLoading";
import TypographyLineBreak from "../TypographyLineBreak";

function PostList({ posts, theme, getPosts, loading }) {
  const [selectedPostId, setSelectedPostId] = useState("");
  const [isReplyingId, setIsReplyingId] = useState("");
  const [user, replies] = useSelector((states) => [
    states.authReducer.user,
    states.forumReducer.replies,
  ]);
  const [imgIdx, setImgIdx] = useState(null);
  const [postIdx, setPostIdx] = useState(null);

  const imgs = posts
    ? posts[postIdx]?.imgs?.map((rImg) => ({ src: rImg })) || []
    : [];

  return (
    <InfiniteScroll
      dataLength={posts?.length}
      next={() => getPosts(posts.length)}
      endMessage={
        user?._id || loading ? (
          <></>
        ) : (
          <EndDiv>
            <Typography variant={"h6"}>
              Desbloqueie a versao Plus para mais
            </Typography>
          </EndDiv>
        )
      }
      hasMore={posts?.length && !user._id ? posts?.length <= 14 : posts.length}
    >
      {posts?.map((rPost, idx) => (
        <PostCard
          user={user}
          post={rPost}
          theme={theme}
          setSelectedPostId={setSelectedPostId}
          selectedPostId={selectedPostId}
          setIsReplyingId={setIsReplyingId}
          isReplyingId={isReplyingId}
          replies={replies}
          setImgIdx={(i) => {
            setImgIdx(i);
            setPostIdx(idx);
          }}
        />
      ))}
      {loading ? <Loading /> : <></>}
      <ImgsViewer
        imgs={imgs}
        currImg={imgIdx}
        isOpen={imgIdx !== null}
        onClickPrev={() => setImgIdx(imgIdx - 1)}
        onClickNext={() => setImgIdx(imgIdx + 1)}
        onClose={() => setImgIdx(null)}
      />
    </InfiniteScroll>
  );
}

function PostCard({
  user,
  post,
  theme,
  setSelectedPostId,
  selectedPostId,
  setIsReplyingId,
  isReplyingId,
  replies,
  setImgIdx,
}) {
  const dispatch = useDispatch();

  function handleLike() {
    forumService
      .likePost(post._id)
      .then((rResponse) => {
        dispatch(handleChangeAllLists(post._id, rResponse, "isLiked"));
      })
      .catch((e) => {
        console.log(e);
        showToast.error("Um erro ocorreu");
      });
  }
  function handleFavorite() {
    forumService
      .favoritePost(post._id)
      .then((rResponse) => {
        dispatch(handleChangeAllLists(post._id, rResponse, "isFavorited"));
      })
      .catch((e) => {
        console.log(e);
        showToast.error("Um erro ocorreu");
      });
  }
  return (
    <Box>
      <div style={{ minHeight: 110, marginBottom: 1 }}>
        <div
          style={{
            display: "flex",
            padding: 12,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography noWrap style={{ marginRight: 12, fontWeight: "bold" }}>
              {post.titulo}
            </Typography>
            <PostInfo post={post} theme={theme} user={user} />
          </div>
          <TypographyLineBreak
            texto={post.texto}
            variant="body2"
            style={{
              color: theme === DARK ? grey[500] : grey[700],
            }}
          />
          <div style={{ display: "flex" }}>
            {post.imgs?.map((rImg, i) => (
              <img
                src={rImg}
                width={60}
                height={60}
                onClick={() => setImgIdx(i)}
                style={{
                  marginRight: 10,
                  borderRadius: 4,
                  cursor: "pointer",
                  zIndex: 2,
                }}
                alt=""
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: post.imgs?.length ? 0 : -18,
              marginBottom: -7,
            }}
          >
            {isReplyingId === post._id ? (
              <ReplyBox
                post={post}
                theme={theme}
                setIsReplyingId={setIsReplyingId}
                setSelectedPostId={setSelectedPostId}
              />
            ) : (
              <div />
            )}
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              {user._id ? (
                <>
                  <IconButton onClick={() => setIsReplyingId(post._id)}>
                    <Reply
                      fontSize="small"
                      style={{
                        color: theme === DARK ? "white" : grey[400],
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={() => handleFavorite(post._id)}>
                    <Star
                      fontSize="small"
                      style={{
                        transition: "0.3s",
                        color: post.isFavorite
                          ? "#ffd700"
                          : theme === DARK
                          ? "white"
                          : grey[400],
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
              <IconButton
                onClick={() =>
                  setSelectedPostId(selectedPostId === post._id ? "" : post._id)
                }
              >
                <Comment
                  fontSize="small"
                  style={{
                    color: theme === DARK ? "white" : grey[400],
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">{post?.repliesCount}</Typography>
              </IconButton>

              <IconButton
                onClick={() => {
                  user._id
                    ? handleLike(post._id)
                    : showToast.error("Funcionalidade para usuarios");
                }}
              >
                <ThumbUp
                  fontSize="small"
                  style={{
                    transition: "0.3s",
                    color:
                      user._id && post.isLiked
                        ? green[700]
                        : theme === DARK
                        ? "white"
                        : grey[400],
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {post.likes?.length || 0}
                </Typography>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {selectedPostId === post._id ? (
        <PostRepliesList
          post={post}
          theme={theme}
          repliesList={replies[selectedPostId]}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
export default PostList;

function ReplyBox({ post, theme, setIsReplyingId, setSelectedPostId }) {
  const classes = useStyles(theme)();
  const [replyText, setReplyText] = useState("");
  const dispatch = useDispatch();

  function handleReply() {
    forumService
      .postReply(post._id, replyText)
      .then((rReply) => {
        dispatch(handleAddReplyCount(post._id, rReply));
        setSelectedPostId(post._id);
        setIsReplyingId("");
        dispatch(addReply(post._id, rReply));
      })
      .catch((e) => console.log(e));
  }
  return (
    <div style={{ width: "100%", position: "relative", marginTop: 30 }}>
      <TextareaAutosize
        rows={2}
        placeholder="Digite uma resposta"
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
        className={classes.textArea}
      />
      <Close
        style={{
          position: "absolute",
          top: 15,
          right: 15,
          width: 20,
          height: 20,
          backgroundColor: theme === DARK ? grey[800] : grey[200],
          opacity: 0.7,
          cursor: "pointer",
        }}
        onClick={() => setIsReplyingId("")}
      />

      <Send
        style={{
          padding: replyText ? 3 : 0,
          position: "absolute",
          bottom: 10,
          right: 15,

          width: replyText ? 20 : 0,

          height: replyText ? 20 : 0,
          backgroundColor: theme === DARK ? grey[800] : grey[200],
          opacity: 0.7,
          cursor: "pointer",
          transition: "0.3s",
        }}
        variant="outlined"
        onClick={handleReply}
      />
    </div>
  );
}

function Loading() {
  return (
    <EndDiv>
      <TCLoading size={40} />
    </EndDiv>
  );
}
function EndDiv({ children }) {
  return (
    <div
      style={{
        height: 100,
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
}
