const BEBIDA_BASE = require("./bebidabase").default;
const MUNICIPIOS = require("./municipios").default;

const PRACAS_DE_COMERCIALIZACAO = [
  "Alfenas",
  "Varginha",
  "Manhuacu",
  "Manhumirim",
];

const ESPECIE_CAFE = {
  A: {
    label: "Arabica",
    value: "Arabica",
    detail: "Arabica",
  },
  R: {
    label: "Robusta",
    value: "Robusta",
    detail: "Robusta",
  },
};

//const TIPO_OFERTA = [
//    "F - Firme",
//    "N - Nominal"
//];

//Novo
const TIPO_OFERTA = {
  F: {
    label: "F - Firme",
    value: "F",
    detail: "Firme",
  },
  N: {
    label: "N - Nominal",
    value: "N",
    detail: "Nominal",
  },
};

//const REGIOES_PRODUTORAS = [
//  "Ba - Bahia",
//  "CM - Cerrado Mineiro",
//  "ES - Espírito Santo",
//  "Mg - Mogiana",
//  "Pa - Paraná",
//  "SM - Sul de Minas",
//  "Ro - Rondônia",
//  "ZM - Zona da Mata",
//];

const REGIOES_PRODUTORAS = {
  
  MG: {
    label: "MINAS GERAIS",
    value: "MG",
    detail: "MG",
  },
  MG0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Minas Gerais",
    value: "Minas Gerais",
    detail: "Minas Gerais",
  },

  MG1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Sul de Minas",
    value: "Sul de Minas",
    detail: "Sul de Minas",
  },
  MG2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Campos das Vertentes",
    value: "Campos das Vertentes",
    detail: "Campos das Vertentes",
  },
  MG3: {
    label: '\xa0'+'\xa0'+'\xa0'+"Cerrado Mineiro",
  value: "Cerrado Mineiro",
  detail: "Cerrado Mineiro",
   },
   MG4: {
    label: '\xa0'+'\xa0'+'\xa0'+"Chapadas de Minas",
    value: "Chapadas de Minas",
    detail: "Chapadas de Minas",
  },
  MG5: {
    label: '\xa0'+'\xa0'+'\xa0'+"Mantiqueira de Minas",
    value: "Mantiqueira de Minas",
    detail: "Mantiqueira de Minas",
  },
  MG6: {
    label: '\xa0'+'\xa0'+'\xa0'+"Matas de Minas",
    value: "Matas de Minas",
    detail: "Matas de Minas",
  },
  MG7: {
    label: '\xa0'+'\xa0'+'\xa0'+"Montanhas de Minas",
    value: "Montanhas de Minas",
    detail: "Montanhas de Minas",
  },
  MG8: {
    label: '\xa0'+'\xa0'+'\xa0'+"Norte de Minas",
    value: "Norte de Minas",
    detail: "Norte de Minas",
  },
  MG9: {
    label: '\xa0'+'\xa0'+'\xa0'+"Caparao - MG",
    value: "Caparao-MG",
    detail: "Caparao-MG",
  },
  MG10: {
    label: '\xa0'+'\xa0'+'\xa0'+"Regiao Vulcanica - MG",
    value: "Regiao Vulcanica-MG",
    detail: "Regiao Vulcanica-MG",
  },





  SP: {
     label: "SAO PAULO",
     value: "SAO PAULO",
     detail: "SAO PAULO",
  },
  SP0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Sao Paulo",
    value: "Sao Paulo",
    detail: "Sao Paulo",
  },
  SP1: {
     label: '\xa0'+'\xa0'+'\xa0'+"Alta Mogiana",
     value: "Alta Mogiana",
     detail: "Alta Mogiana",
   },
   SP2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Media Mogiana",
    value: "Media Mogiana",
    detail: "Media Mogiana",
  },
  SP3: {
    label: '\xa0'+'\xa0'+'\xa0'+"Marilia e Garca",
    value: "Marilia e Garca",
    detail: "Marilia e Garca",
  },
  SP4: {
    label: '\xa0'+'\xa0'+'\xa0'+"Ourinhos e Avare",
    value: "Ourinhos e Avare",
    detail: "Ourinhos e Avare",
  },
  SP5: {
    label: '\xa0'+'\xa0'+'\xa0'+"Regiao de Pinhal",
    value: "Regiao de Pinhal",
    detail: "Regiao de Pinhal",
  },
  SP6: {
    label: '\xa0'+'\xa0'+'\xa0'+"Regiao Vulcanica - SP",
    value: "Regiao Vulcanica-SP",
    detail: "Regiao Vulcanica-SP",
  },

  BA: {
    label: "BAHIA",
    value: "BAHIA",
    detail: "BAHIA",
  },
  BA0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Bahia",
    value: "Bahia",
    detail: "Bahia",
  },
  BA1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Chapada Diamantina",
    value: "Chapada Diamantina",
    detail: "Chapada Diamantina",
  },
  BA2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Planalto de Vitoria da Conquista",
    value: "Planalto de Vitoria da Conquista",
    detail: "Planalto de Vitoria da Conquista",
   },
   BA3: {
    label: '\xa0'+'\xa0'+'\xa0'+"Serrana de Itiruçu/Brejões",
    value: "Serrana de Itiruçu/Brejões",
    detail: "Serrana de Itiruçu/Brejões",
   },
   BA4: {
    label: '\xa0'+'\xa0'+'\xa0'+"Oeste da Bahia",
    value: "Oeste da Bahia",
    detail: "Oeste da Bahia",
   },
   BA5: {
    label: '\xa0'+'\xa0'+'\xa0'+"Atlantico Baiano",
    value: "Atlantico Baiano",
    detail: "Atlantico Baiano",
   },
   ES: {
    label: "ESPIRITO SANTO",
    value: "ESPIRITO SANTO",
    detail: "ESPIRITO SANTO",
  },
  ES0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Espirito Santo",
    value: "Espirito Santo",
    detail: "Espirito Santo",
   },
  ES1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Montanhas do Espirito Santo",
    value: "Montanhas do Espirito Santo",
    detail: "Montanhas do Espirito Santo",
   },
   ES2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Conilon Capixaba",
    value: "Conilon Capixaba",
    detail: "Conilon Capixaba",
   },
   ES3: {
    label: '\xa0'+'\xa0'+'\xa0'+"Caparao - ES",
    value: "Caparao-ES",
    detail: "Caparao-ES",
  },


   PR: {
    label: "PARANA",
    value: "PR",
    detail: "PR",
  },
  PR0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Parana",
    value: "Parana",
    detail: "Parana",
   },
  PR1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Norte Pioneiro do Parana",
    value: "Norte Pioneiro do Parana",
    detail: "Norte Pioneiro do Parana",
   },
   PR2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Parana",
    value: "Norte Pioneiro do Paraná",
    detail: "Norte Pioneiro do Paraná",
   },
  
  RO: {
    label: "RONDONIA",
    value: "RO",
    detail: "RO",
  },
  RO1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Rondonia",
    value: "Rondonia",
    detail: "Rondonia",
   },
   RO2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Matas de Rondonia",
    value: "Matas de Rondonia",
    detail: "Matas de Rondonia",
   },

  RJ: {
    label: "RIO DE JANEIRO",
    value: "RIO DE JANEIRO",
    detail: "RIO DE JANEIRO",
  },
  RJ0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Rio de Janeiro",
    value: "Rio de Janeiro",
    detail: "Rio de Janeiro",
   },
  RJ1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Noroeste/Norte do Rio de Janeiro",
    value: "Noroeste/Norte do RJ",
    detail: "Noroeste/Norte do RJ",
   },
   RJ2: {
    label: '\xa0'+'\xa0'+'\xa0'+"Serrana do Rio de Janeiro",
    value: "Serrana do RJ",
    detail: "Serrana do RJ",
   },
   RJ3: {
    label: '\xa0'+'\xa0'+'\xa0'+"Sul do Rio de Janeiro ",
    value: "SUL DO RJ",
    detail: "Sul do RJ",
   },

  CE: {
    label: "CEARA",
    value: "CE",
    detail: "CE",
  },
  CE1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Ceara ",
    value: "Ceara",
    detail: "Ceara",
   },

   


  GO: {
    label: "GOIAS",
    value: "GO",
    detail: "GO",
  },
  GO1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Goias ",
    value: "Gois",
    detail: "Goias",
   },


  
  PE: {
    label: "PERNAMBUCO",
    value: "PE",
    detail: "PE",
  },
  PE1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Pernambuco ",
    value: "Pernambuco",
    detail: "Pernambuco",
   },
  
  DF: {
    label: "DISTRITO FEDERAL",
    value: "DF",
    detail: "DF",
  },
  DF0: {
    label: '\xa0'+'\xa0'+'\xa0'+"Destrito Federal",
    value: "Distrito Federal",
    detail: "Distrito Federal",
   },
  DF1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Planalto Central",
    value: "Planalto Central",
    detail: "Planalto Central",
   },


  AC: {
    label: "ACRE",
    value: "AC",
    detail: "AC",
  },
  AC1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Acre",
    value: "Acre",
    detail: "Acre",
   },


  MT: {
    label: "MATO GROSSO",
    value: "MT",
    detail: "MT",
  },
  MT1: {
    label: '\xa0'+'\xa0'+'\xa0'+"Mato Grosso",
    value: "Mato Grosso",
    detail: "Mato Grosso",
   },


};

export const ANO_SAFRA = {
  "24/25": {
    value: "24/25",
    label: "24/25",
    detail: "24/25",
    sortingKey: "93",
  },
  "23/24": {
    value: "23/24",
    label: "23/24",
    detail: "23/24",
    sortingKey: "94",
  },
  "22/23": {
    value: "22/23",
    label: "22/23",
    detail: "22/23",
    sortingKey: "95",
  }
};


export const TIPOS_EMPRESAS = ["Armazem","Comerciante", "Cooperativa",  "Corretor", "Exportador", "Industria"];
// ************************************************************************
// ************************************************************************
// ************************************************************************
// ************************************************************************

//Antigo
// const FAVA = ["C - Chata", "M - Moca"];

//Novo
export const FAVA = {
  BC: {
  //  label: "B/C - Bica Corrida",
    label: "Bica Corrida",
    detail: "Bica Corrida",
    value: "B/C",
  },
  CI: {
    label: "Consumo Interno",
    detail: "Consumo Interno",
    value: "C.Interno",
  },
  Pe: {
    //label: "Pe - Peneirado (Beneficiado)",
    label: "Peneirado (Beneficiado)",
    detail: "Peneirado (Beneficiado)",
    value: "Peneira",
  },
  Es: {
  //  label: "Es - Escolha",
    label: "Escolha",
    detail: "Escolha",
    value: "Escolha",
  },
};

// ************************************************************************
// ************************************************************************
// ************************************************************************
// ************************************************************************

//const PROCESSAMENTO = [
//  "NT - Natural",
//  "CD - Cereja Descascado",
//  "CP - Cereja Despolpado",
//];

export const PROCESSAMENTO = {
  CD: {
    label: "Cereja Descascado",
    detail: "Cereja Descascado",
    value: "C.Descascado",
  },
  CP: {
    label: "Cereja Despolpado",
    detail: "Cereja Despolpado",
    value: "C.Despolpado",
  },
  NT: {
    label: "Natural",
    detail: "Natural",
    value: "Natural",
  },
};

//const ASPECTO = [
//  "F - Fino       - Grãos Excelentes, uniformes no tamanho, cor e seca",
//  "R - Regular    - Quando falhar uma das condicões acima",
//  "M - Mau        - Com a maioria ou todas as condições falhas",
//];

export const ASPECTO = {
  F: {
    label:
      "F - Fino       - Grãos Excelentes, uniformes no tamanho, cor e seca",
    value: "F",
    detail: "Fino",
  },
  R: {
    label: "R - Regular    - Quando falhar uma das condicões acima",
    value: "R",
    detail: "Regular",
  },
  M: {
    label: "M - Mau        - Com a maioria ou todas as condições falhas",
    value: "M",
    detail: "Mau",
  },
};

// const BEBIDA_BASE = [
//   "EM	    	- Estritamente Mole",
//   "M 		    - Mole",
//   "AM 	  	    - Apenas Mole",
//   "D 			- Duro",
//   "DRd  	    - Duro/Riado",
//   "DRdRio	    - Duro/Riado/Rio",
//   "Rd 		    - Riado",
//   "RdRio	 	- Riado/Rio",
//   "Rio	   	    - Rio",
//   "RioZ	  	    - Rio Zona",
// ];

const TIPO_DO_CAFE = [
  "2",
  "2/3",
  "3",
  "3/4",
  "4",
  "4/5",
  "5",
  "5/6",
  "6",
  "6/7",
  "7",
  "7/8",
  "8",
];

const DEFEITO_DO_CAFE = [
  " 4 Defeitos",
  " 5 Defeitos",
  " 6 Defeitos",
  " 7 Defeitos",
  " 8 Defeitos",
  " 9 Defeitos",
  "10 Defeitos",
  "11 Defeitos",
 
  "-",
  "12 Defeitos",
  "13 Defeitos",
  "15 Defeitos",
  "17 Defeitos",
   "18 Defeitos",
   "19 Defeitos",
   "20 Defeitos",
   "22 Defeitos",
   "23 Defeitos",
   "25 Defeitos",
   "BASE",
   "26 Defeitos",
   "28 Defeitos",
   "30 Defeitos",
   "32 Defeitos",
   "34 Defeitos",
   "36 Defeitos",
   "38 Defeitos",
   "40 Defeitos",
   "42 Defeitos",
   "44 Defeitos",
   "-",
   "46 Defeitos",
   "49 Defeitos",
   "53 Defeitos",
   "57 Defeitos",
   "61 Defeitos",
   "64 Defeitos",
   "68 Defeitos",
   "71 Defeitos",
   "75 Defeitos",
   "79 Defeitos",
   "-",
   "86 Defeitos",
   "93 Defeitos",
   "100 Defeitos",
   "108 Defeitos",
   "115 Defeitos",
   "123 Defeitos",
   "130 Defeitos",
   "138 Defeitos",
   "145 Defeitos",
   "153 Defeitos",
   "-",
   "160 Defeitos",
   "180 Defeitos",
   "200 Defeitos",
   "220 Defeitos",
   "240 Defeitos",
   "260 Defeitos",
   "280 Defeitos",
   "300 Defeitos",
   "320 Defeitos",
   "340 Defeitos",
   "-",
   "360 Defeitos",
   "400 Defeitos",
   "400 Defeitos 1:1",
   "400 Defeitos 2:2",
   "450 Defeitos",
   "450 Defeitos 1:1",
   "450 Defeitos 2:2",
   "500 Defeitos",
   "500 Defeitos 1:1",
   "500 Defeitos 2:2",
  "600 Defeitos",
  "600 Defeitos 1:1",
  "600 Defeitos 2:2",
  "700 Defeitos",
  "700 Defeitos 1:1",
  "700 Defeitos 2:2",
  "800 Defeitos",
  "800 Defeitos 1:1",
  "800 Defeitos 2:2",
  "ate 800 Defeitos",
  "Nao Enquadrado",


];
/*
const DEFEITO_DO_CAFE = [
  " 4 Defeitos/ Tipo 2    / P+100",
  " 4 Defeitos/ Tipo 2-5  / P+95",
  " 5 Defeitos/ Tipo 2-10 / P+90",
  " 6 Defeitos/ Tipo 2-15 / P+85",
  " 7 Defeitos/ Tipo 2-20 / P+80",
  " 8 Defeitos/ Tipo 2-25 / P+75",
  " 9 Defeitos/ Tipo 2-30 / P+70",
  "10 Defeitos/ Tipo 2-35 / P+65",
  "11 Defeitos/ Tipo 2-40 / P+60",
  "11 Defeitos/ Tipo 2-45 / P+55",
  "12 Defeitos/ Tipo 3    / P+50",
  "13 Defeitos/ Tipo 3-5  / P+45",
  "15 Defeitos/ Tipo 3-10 / P+45",
  "17 Defeitos/ Tipo 3-15 / P+35",
  // "18   Defeitos/ Tipo 3-20
  // /Pontos + 30"
  // "19   Defeitos/ Tipo 3-25
  // /Pontos + 25"
  // "20   Defeitos/ Tipo 3-30
  // /Pontos + 20"
  // "22   Defeitos/ Tipo 3-35
  // /Pontos + 15"
  // "23   Defeitos/ Tipo 3-40
  // /Pontos + 10"
  // "25   Defeitos/ Tipo 3-45
  // /Pontos + 5"
  // -""
  // "26   Defeitos/ Tipo 4
  // BASE"
  // "28   Defeitos/ Tipo 4-5
  // /Pontos - 5"
  // "30   Defeitos/ Tipo 4-10
  // /Pontos - 10"
  // "32   Defeitos/ Tipo 4-15
  // /Pontos - 15"
  // "34   Defeitos/ Tipo 4-20
  // /Pontos - 20"
  // "36   Defeitos/ Tipo 4-25
  // /Pontos - 25"
  // "38   Defeitos/ Tipo 4-30
  // /Pontos - 30"
  // "40   Defeitos/ Tipo 4-35
  // /Pontos - 35"
  // "42   Defeitos/ Tipo 4-40
  // /Pontos - 40"
  // "44   Defeitos/ Tipo 4-45
  // /Pontos - 45"
  // -""
  // "46   Defeitos/ Tipo 5
  // /Pontos - 50"
  // "49   Defeitos/ Tipo 5-5
  // /Pontos - 55"
  // "53   Defeitos/ Tipo 5-10
  // /Pontos - 60"
  // "57   Defeitos/ Tipo 5-15 /Pontos - 65"
  // "61   Defeitos/ Tipo 5-20
  // /Pontos - 70"
  // "64   Defeitos/ Tipo 5-25
  // /Pontos - 75"
  // "68   Defeitos/ Tipo 5-30
  // /Pontos - 80"
  // "71   Defeitos/ Tipo 5-35
  // /Pontos - 85"
  // "75   Defeitos/ Tipo 5-40
  // /Pontos - 90"
  // "79   Defeitos/ Tipo 5-45
  // /Pontos - 95"
  // -""
  // "86   Defeitos/ Tipo 6
  // /Pontos - 100"
  // "93   Defeitos/ Tipo 6-5
  // /Pontos - 105"
  // "100  Defeitos/ Tipo 6-10 /Pontos - 110"
  // "108  Defeitos/ Tipo 6-15
  // /Pontos - 115"
  // "115  Defeitos/ Tipo 6-20
  // /Pontos - 120"
  // "123  Defeitos/ Tipo 6-25
  // /Pontos - 125"
  // "130  Defeitos/ Tipo 6-30
  // /Pontos - 130"
  // "138  Defeitos/ Tipo 6-35
  // /Pontos - 135"
  // "145  Defeitos/ Tipo 6-40
  // /Pontos - 140"
  // "153  Defeitos/ Tipo 6-45
  // /Pontos - 145"
  // -""
  // "160  Defeitos/ Tipo 7
  //     /Pontos - 150"
  // "180  Defeitos/ Tipo 7-5
  // /Pontos - 155"
  // "200  Defeitos/ Tipo 7-10
  // /Pontos - 160"
  // "220  Defeitos/ Tipo 7-15
  // /Pontos - 165"
  // "240  Defeitos/ Tipo 7-20
  // /Pontos - 170"
  // "260  Defeitos/ Tipo 7-25
  // /Pontos - 175"
  // "280  Defeitos/ Tipo 7-30
  // /Pontos - 180"
  // "300  Defeitos/ Tipo 7-35
  // /Pontos - 185"
  // "320  Defeitos/ Tipo 7-40
  // /Pontos - 190"
  // "340  Defeitos/ Tipo 7-45
  // /Pontos - 195"
  // -""
  // "360  Defeitos/ Tipo 8 / Pontos - 200",
   "400  Defeitos/ Tipo 8 ",
   "450  Defeitos/ Tipo 8 ",
   "500  Defeitos/ Tipo 8 ",
   "600  Defeitos/ Tipo 8 ",
   "700  Defeitos/ Tipo 8 ",
   "800  Defeitos/ Tipo 8 ",
];
*/

//const COR_DO_CAFE = [
//  "V 	    - Verde",
//  "VCa      - Verde Cana",
//  "E	    - Esverdeado",
//  "VCl      - Verde Clara",
//  "VA       - Verde Azulado",
//  "A        - Amarelada",
//  "ACl      - Amarela Clara",
//  "Es       - Esbranquiçada",
//  "B        - Branco",
//];

const PONTOS_SCA = [
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];

export const COR_DO_CAFE = {
  V: {
    label: "V 	    - Verde",
    value: "V",
    detail: "Verde",
  },
  VCa: {
    label: "VCa 	- Verde Cana",
    value: "VCa",
    detail: "Verde Cana",
  },
  E: {
    label: "E	    - Esverdeada",
    value: "E",
    detail: "Esverdeada",
  },

  VCl: {
    label: "VCl	    - Verde Claro",
    value: "VCl",
    detail: "Verde Claro",
  },
  VA: {
    label: "VA	    - Verde Azulado",
    value: "VA",
    detail: "Verde Azulado",
  },
  A: {
    label: "A	    - Amarelada",
    value: "A",
    detail: "Amarelada",
  },
  ACl: {
    label: "ACl	    - Amarela Claro",
    value: "ACl",
    detail: "Amarela Claro",
  },
  Es: {
    label: "Es	    - Esbranquicada",
    value: "Es",
    detail: "Esbranquicada",
  },
  B: {
    label: "B	    - Branco",
    value: "B",
    detail: "Branco",
  },
};

// const PADRAO_DO_CAFE = [
//   "E - Especiais",
//   "F - Finos",
//   "C - Comerciais",
//   "I - Inferiores",
// ];

export const PADRAO_DO_CAFE = {

  F: {
    label: "F 	    - Fino",
    value: "F",
    detail: "Fino",
  },
    E: {
    label: "E 	    - Especial",
    value: "E",
    detail: "Especial",
  },

  C: {
    label: "C	    - Comercial",
    value: "C",
    detail: "Comercial",
  },
  I: {
    label: "CI	    - Consumo Interno",
    value: "CI",
    detail: "Consumo Interno",
  },
};

const GOSTOS_ADICIONAIS = [
  "ExtraFino",
  "FC",
  "GC",
  "Fino",
  "Remanescente",
  "Novo",
  "Novissimo",
  "Strong",
  "Good",
  "Suave",
  "Fraco",
  "Neutro",
  "Forte",
  "Uniforme",
  "Limpo",
  "Pano",
  "1 Xc Riada",
  "2 Xc Riadas",
  "1 Xc Riada",
  "1 XcRiada",
  "Rio Minas",
  "Varricao",
  "Avinagrado",
  "Azedo",
  "Acido",
  "Eucalipto",
  "Sujo",
  "Sujo e Fermantado",
  "Fora de Copo",
  "Fermentado Leve",
  "Fermentado Medio",
  "Fermentado Forte",
  "Fumaça",
  "Mofo",
  "Terra",
  "Verde",
  "Chorume",
 
];
const EMPRESAS = [
  "3 Coracoes",
  "Aroma",
  "Atlantica",
  "Bueno",
  "Costa",
  "Comexim",
  "Copa Cafe",
  "Cooabriel",
  "Constantino",
  "Cocatrel",
  "Cooxupe",
  "Drefyus",
  "Eisa",
  "Gardingo",
  "Mediar",
  "MinaSul",
  "NICCHIO",
  "Olan",
  "Prata Pereira",
  "Sucafina",
  "TPJ",
];

const COMPRA_VENDA = {
  C: {
    value: "C",
    label: "Compra",
    detail: "Compra",
  },
  V: {
    value: "V",
    label: "Venda",
    detail: "Venda",
  },
};
const OBJETO_CAMPOS = {
  modo: {
    key: "modo",
    valores: COMPRA_VENDA,
    // select: ["C - Compra", "V - Venda"],
    display: "Modo",
    tipoDeInput: "SELECT",  
    quickMessageShowColumn: true
  },

  especie: {
    key: "especie",
    valores: ESPECIE_CAFE,
    display: "Especie",
    tipoDeInput: "SELECT",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },

  praca: {
    key: "praca",
    valores: PRACAS_DE_COMERCIALIZACAO,
    // select: PRACAS_DE_COMERCIALIZACAO,
    display: "Praca",
    tipoDeInput: "SELECT",
    width: 30,
  },
  municipio: {
    key: "municipio",
    valores: MUNICIPIOS,
    // select: MUNICIPIOS,
    display: "Municipio Produtor",
    tipoDeInput: "AUTOCOMPLETE",
  },
  regiaoProdutora: {
    key: "regiaoProdutora",
    valores: REGIOES_PRODUTORAS,
    // select: REGIOES_PRODUTORAS,
    display: "Região Produtora",
    tipoDeInput: "SELECT",
    width: 30,
  },

  mr: {
    key: "mr",
    valores: MUNICIPIOS,
    // select: MUNICIPIOS,
     display: "Municipio",
    tipoDeInput: "AUTOCOMPLETE",
  },

  processamento: {
    key: "processamento",
    valores: PROCESSAMENTO,
    // select: PROCESSAMENTO_SELECT,
    display: "Processamento",
    tipoDeInput: "SELECT",
  },

  anoSafra: {
    key: "anoSafra",
    valores: ANO_SAFRA,
    // select: ANO_SAFRA,
    display: "Ano Safra",
    tipoDeInput: "SELECT",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },
  padraoInterno: {
    key: "padraoInterno",
    display: "Meu Padrão",
    tipoDeInput: "INPUT",
    quickMessageShowColumn: true
  },
  bebidaBase: {
    key: "bebidaBase",
    valores: BEBIDA_BASE,
    // select: BEBIDA_BASE,
    display: "Bebida Base",
    tipoDeInput: "SELECT",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },
  gostoAdicional: {
    key: "gostoAdicional",
    display: "Gosto Adicional",
    valores: GOSTOS_ADICIONAIS,
    tipoDeInput: "SELECT",
  },
  catacao: {
    key: "catacao",
    display: "Catacao",
    tipoDeInput: "PORCENTAGEM",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },

  fava: {
    key: "fava",
    valores: FAVA,
    display: "Fava",
    tipoDeInput: "SELECT",
  },

  aspecto: {
    key: "aspecto",
    valores: ASPECTO,
    display: "Aspecto",
    tipoDeInput: "SELECT",
  },

  tipoCafe: {
    key: "tipoCafe",
    valores: TIPO_DO_CAFE,
    display: "Tipo café",
    tipoDeInput: "SELECT",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },

  defeito: {
    key: "defeito",
    valores: DEFEITO_DO_CAFE,
    display: "Defeito café",
    tipoDeInput: "SELECT",
  },

  cor: {
    key: "cor",
    valores: COR_DO_CAFE,
    display: "Cor café",
    tipoDeInput: "SELECT",
    quickMessagegroupBy: true,
    quickMessageShowColumn: true
  },

  padrao: {
    key: "padrao",
    valores: PADRAO_DO_CAFE,
    display: "Padrão café",
    tipoDeInput: "SELECT",
  },

  quantidadeSacos: {
    key: "quantidadeSacos",
    display: "Qtd Sacas 60,5Kg",
    tipoDeInput: "INPUT",
  },

  pontosSCA: {
    key: "pontosSCA",
    display: "Pontos SCA",
    valores: PONTOS_SCA,
    tipoDeInput: "SELECT",
  },

  // precoNY: {
  // key: "precoNY",
  //   display: "Preco Nova York",
  //   tipoDeInput: "INPUT",
  // },
  // dollar: {
  // key: "dollar",
  //   display: "Preco Dollar",
  //   tipoDeInput: "INPUT",
  // },
  // diferencial: {
  // key: "diferencial",
  //   display: "Diferencial",
  //   tipoDeInput: "INPUT",
  // },

  preco: {
    key: "preco",
    display: "Preco 60,5Kg",
    tipoDeInput: "INPUT",
    quickMessageShowColumn: true
  },
  tp: {
    key: "tp",
    valores: TIPO_OFERTA,
    display: "Tipo Oferta",
    tipoDeInput: "SELECT",
    width: 30,
  },

  p20: {
    key: "p20",
    display: "Peneira 20",
    tipoDeInput: "PORCENTAGEM",
  },

  p19: {
    key: "p19",
    display: "Peneira 19",
    tipoDeInput: "PORCENTAGEM",
  },

  p18: {
    key: "p18",
    display: "Peneira 18",
    tipoDeInput: "PORCENTAGEM",
  },

  p17: {
    key: "p17",
    display: "Peneira 17",
    tipoDeInput: "PORCENTAGEM",
  },

  p16: {
    key: "p16",
    display: "Peneira 16",
    tipoDeInput: "PORCENTAGEM",
  },

  p15: {
    key: "p15",
    display: "Peneira 15",
    tipoDeInput: "PORCENTAGEM",
  },

  p14: {
    key: "p14",
    display: "Peneira 14",
    tipoDeInput: "PORCENTAGEM",
  },

  p13: {
    key: "p13",
    display: "Peneira 13",
    tipoDeInput: "PORCENTAGEM",
  },

  f13: {
    key: "f13",
    display: "Fundo13",
    tipoDeInput: "PORCENTAGEM",
  },

  f10: {
    key: "f10",
    display: "Fundo10",
    tipoDeInput: "PORCENTAGEM",
  },

  pva: {
    key: "pva",
    display: "% PVA lote",
    tipoDeInput: "PORCENTAGEM",
  },
  numeroLote: {
    key: "numeroLote",
    display: "Número Lote",
    tipoDeInput: "INPUT",
  },
  fundoLote: {
    key: "fundoLote",
    display: "Fundo lote",
    tipoDeInput: "PORCENTAGEM",
  },
  ql: {
    key: "ql",
    display: "% Quebra Lote",
    tipoDeInput: "PORCENTAGEM",
  },

  teorUmidade: {
    key: "teorUmidade",
    display: "Teor Umidade",
    tipoDeInput: "PORCENTAGEM",
  },

  impurezas: {
    key: "impurezas",
    display: "% Impurezas lote",
    tipoDeInput: "PORCENTAGEM",
  },

  moka: {
    key: "moka",
    display: "% Moka",
    tipoDeInput: "PORCENTAGEM",
  },

  aproveitamento: {
    key: "aproveitamento",
    display: "% Aproveitamento",
    tipoDeInput: "PORCENTAGEM",
  },
  broca: {
    key: "broca",
    display: "% Broca",
    tipoDeInput: "PORCENTAGEM",
  },
  torrao: {
    key: "torrao",
    display: "% Torrao",
    tipoDeInput: "PORCENTAGEM",
  },
  pau: {
    key: "pau",
    display: "% Pau",
    tipoDeInput: "PORCENTAGEM",
  },
  marinheiro: {
    key: "marinheiro",
    display: "% Marinheiro",
    tipoDeInput: "PORCENTAGEM",
  },
  palha: {
    key: "palha",
    display: "% Palha",
    tipoDeInput: "PORCENTAGEM",
  },
  gpi: {
    key: "gpi",
    display: "% GPI",
    tipoDeInput: "PORCENTAGEM",
  },

  certificacao: {
    key: "certificacao",
    display: "Certificação",
    tipoDeInput: "INPUT",
  },

  // obs: {
  //   display: "Observacões",
  //   tipoDeInput: "INPUT",
  // },
};

export default OBJETO_CAMPOS;

const numLoteRow = [OBJETO_CAMPOS.numeroLote, OBJETO_CAMPOS.mr];
const municipiosRow = [OBJETO_CAMPOS.regiaoProdutora, OBJETO_CAMPOS.municipio];
const descricaoColumn1 = [
  OBJETO_CAMPOS.especie,
  OBJETO_CAMPOS.bebidaBase,
  OBJETO_CAMPOS.tipoCafe,
  OBJETO_CAMPOS.aspecto,
];
const descricaoColumn2 = [
  OBJETO_CAMPOS.anoSafra,
  OBJETO_CAMPOS.gostoAdicional,
  OBJETO_CAMPOS.defeito,
  OBJETO_CAMPOS.certificacao,
];
const descricaoColumn3 = [
  OBJETO_CAMPOS.fava,
  OBJETO_CAMPOS.catacao,
  OBJETO_CAMPOS.padrao,
  OBJETO_CAMPOS.pontosSCA,
  
  
];
const descricaoColumn4 = [
  OBJETO_CAMPOS.processamento,
  OBJETO_CAMPOS.teorUmidade,
  
  OBJETO_CAMPOS.cor,
  
  OBJETO_CAMPOS.quantidadeSacos,
];

const descricaoRow = [
  descricaoColumn1,
  descricaoColumn2,
  descricaoColumn3,
  descricaoColumn4,
];

const peneirasRow = [
  OBJETO_CAMPOS.p20,
  OBJETO_CAMPOS.p19,
  OBJETO_CAMPOS.p18,
  OBJETO_CAMPOS.p17,
  OBJETO_CAMPOS.p16,
  OBJETO_CAMPOS.p15,
  OBJETO_CAMPOS.p14,
  OBJETO_CAMPOS.p13,
  OBJETO_CAMPOS.f13,
  OBJETO_CAMPOS.f10,
  OBJETO_CAMPOS.moka,
];

const complementoRow = [
  [OBJETO_CAMPOS.aproveitamento, OBJETO_CAMPOS.pva],
  [OBJETO_CAMPOS.ql, OBJETO_CAMPOS.impurezas],
  [OBJETO_CAMPOS.broca, OBJETO_CAMPOS.torrao],
  [OBJETO_CAMPOS.pau, OBJETO_CAMPOS.marinheiro],
  [OBJETO_CAMPOS.palha, OBJETO_CAMPOS.gpi],
];

export { numLoteRow, municipiosRow, descricaoRow, peneirasRow, complementoRow };
