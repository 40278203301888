import React from "react";
import { Typography, Card, makeStyles } from "@material-ui/core";
import { Link } from "@material-ui/icons";
import { useSelector } from "react-redux";
import CopyToClipboard from "@components/CopyToClipboard";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 382,
    minHeight: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function InviteToTradeCafeCopyLink({ empresa }) {
  const classes = useStyles();

  const [user] = useSelector((states) => [states.authReducer.user]);
  function getRegisterLink() {
    const urlParts = window.location.href.split("/");
    // Extracting the parts until the third "/"
    const truncatedUrl = urlParts.slice(0, 3).join("/");
    return truncatedUrl + `/#/register?referralId=${user?._id}`;
  }

  return (
    <Card className={classes.card}>
      <CopyToClipboard
        copyText={getRegisterLink()}
        icon={<Link fontSize="large" />}
        hideText
        width={100}
        flex
      ></CopyToClipboard>
      <Typography variant="body1">Convidar por Copia e cola</Typography>
    </Card>
  );
}
