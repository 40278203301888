import React, { useEffect, useState } from "react";
import { Box, Typography, Card, makeStyles } from "@material-ui/core";
import { Email, Link } from "@material-ui/icons";
import InviteUserToCompanyModal from "../components/Company/InviteUserToCompanyModal";
import InviteToTradeCafeCopyLink from "./InviteToTradeCafeCopyLink";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 382,
    minHeight: 120,
    cursor: "pointer",
    padding: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 0.3s ease, background-color 0.3s ease", // Add transition for transform and background-color
    "&:hover": {
      transform: "scale(1.05)", // Grow card on hover
    },
  },
}));

export default function InviteToTradeCafe({ empresa }) {
  const [lista, setLista] = useState({});
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onSuccessInviteToCompany(pConvite) {
    const copyLista = { ...lista };
    copyLista.convites.push(pConvite);
    setLista(copyLista);
  }

  function sendMailModalOpen() {
    return setIsModalOpen(true);
  }

  return (
    <Box
      style={{
        marginTop: 60,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 24,
      }}
    >
      {/* <InviteToTradeCafeCopyLink /> */}

      <Card className={classes.card} onClick={sendMailModalOpen}>
        <Email fontSize="large" />

        <Typography
          variant="body1"
          style={{ marginLeft: 34, textAlign: "center",maxWidth: 190 }}
        >
          Convidar Novo Usuario para o TradeCafe por Email
        </Typography>
      </Card>

      <InviteUserToCompanyModal
        handleClose={() => setIsModalOpen(false)}
        showModal={isModalOpen}
        onSuccess={onSuccessInviteToCompany}
      />
    </Box>
  );
}
