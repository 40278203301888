import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Radio,
  TextField,
} from "@material-ui/core";
import { useStyles } from "./Profile";
import MainAnimation from "../components/MainAnimation";
import showToast from "../components/Toast";
import feedbackService from "../services/feedbackService";
import TCButton from "../components/TCButton";

const red = "#f5372a";

function Feedback({ history }) {
  const [feedback, setFeedback] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorTexto, setErrorTexto] = useState("");
  const classes = useStyles();

  function handleSubmit() {
    if (!feedback.texto) {
      return setErrorTexto(true);
    }

    setLoading(true);
    feedbackService
      .postFeedback(feedback)
      .then(() => {
        showToast.success("Feedback enviado com sucesso");
        setFeedback({});
      })
      .catch((e) => {
        showToast.error(e?.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper className={classes.paper}>
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            Ajude-nos a melhorar compartilhando seu feedback.
          </Typography>
          <RadioButton
            label="Reclamação"
            name="reclamacao"
            setFeedback={setFeedback}
            feedback={feedback}
          />
          <RadioButton
            label="Sugestão"
            name="sugestao"
            setFeedback={setFeedback}
            feedback={feedback}
          />
          <RadioButton
            label="Duvida"
            name="duvida"
            setFeedback={setFeedback}
            feedback={feedback}
          />
          {feedback?.tipo && (
            <>
              <TextField
                style={{
                  width: "100%",
                  marginTop: 12,
                  border: errorTexto && `2px solid ${red}`,
                }}
                variant="outlined"
                multiline
                placeholder={`Digite aqui sua ${feedback.tipo}`}
                value={feedback.texto}
                onChange={(e) =>
                  setFeedback({ ...feedback, texto: e.target.value })
                }
                minRows={4}
                maxRows={6}
              />
              {errorTexto && <ErrorText>Preencha o texto</ErrorText>}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <TCButton
                  loading={loading}
                  onClick={handleSubmit}
                  style={{ minWidth: 150 }}
                >
                  Enviar
                </TCButton>
              </div>
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Feedback;

function RadioButton({ label, name, feedback, setFeedback }) {
  function handleChangeTipo(e) {
    const copy = { ...feedback, tipo: e.target.checked && name };
    setFeedback(copy);
  }
  return (
    <div>
      <Radio
        color="primary"
        checked={feedback.tipo === name}
        onChange={handleChangeTipo}
      />
      <Typography variant="body">{label}</Typography>
    </div>
  );
}

function ErrorText({ children }) {
  return (
    <Typography variant="subtitle1" style={{ color: red }}>
      {children}
    </Typography>
  );
}
